<template>
  <v-container>
    <v-card
      elevation="0"
      class="indigo"
      style="background: linear-gradient(45deg, #03a9f4, #0000007d)"
    >
      <v-card-title class="">
        <span>证书</span>
      </v-card-title>
    </v-card>
    <div class="px-3">
      <v-row>
        <v-col cols="2"><span class="font-weight-bold">用户名</span></v-col>
        <v-col cols="10"
          ><span>{{ username }}</span></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="2"><span class="font-weight-bold">token</span></v-col>
        <v-col cols="10"
          ><span style="word-wrap: break-word">{{ token }}</span></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>
<script>
import axios from "axios"; //基于Promise的HTTP客户端
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl,
  version = commonCfg.urlVersion;
export default {
  data() {
    return {
      username: "",
      token: "",
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    async getToken() {
      axios
        .get(`${commonUrl}getSystemToken${version}`)
        .then(res => {
          this.username = res.data.result.username;
          this.token = res.data.result.token;
        })
        .catch(() => {
          this.username = "";
          this.token = "";
        });
    },
  },
};
</script>